<template>
  <div class="">
    <template v-for="(action, index) in defaultActions">
      <ez-button
        :key="action.event"
        :type="action.type || 'text'"
        :disabled="action.disabled"
        @click="onTriggerEvent(action.event)"
      >
        {{ $t(action.label) }}
      </ez-button>
      <ez-divider
        v-if="(moreActions && moreActions.length) || (defaultActions && defaultActions.length > 1 && index !== defaultActions.length - 1)"
        :key="action.event"
        direction="vertical"
      />
    </template>
    <ez-dropdown
      v-if="moreActions && moreActions.length"
      @command="handleCommand"
    >
      <ez-button
        type="text"
        class="el-dropdown-link"
      >
        {{ $t('common.more') }}
        <i class="ez-icon-arrow-down ez-icon--right" />
      </ez-button>
      <ez-dropdown-menu slot="dropdown">
        <ez-dropdown-item
          v-for="action in moreActions"
          :key="action.event"
          :disabled="action.disabled"
          :divided="action.divided"
          :command="action.event"
        >
          {{ $t(action.label) }}
        </ez-dropdown-item>
      </ez-dropdown-menu>
    </ez-dropdown>
  </div>
</template>

<script>
export default {
  name: 'RowActions',
  props: {
    actions: {
      type: Object,
      default: () => {
        return {
          default: [
            { event: 'View', label: 'View' },
            { event: 'Edit', label: 'Edit' }
          ],
          more: [
            { event: 'Delete', label: 'Delete' }
          ]
        }
      }
    }
  },
  computed: {
    moreActions () {
      return this.actions?.more?.filter(item => !item.hidden)
    },
    defaultActions () {
      return this.actions?.default?.filter(item => !item.hidden)
    }
  },
  methods: {
    handleCommand (command) {
      if (command) this.onTriggerEvent(command)
    },
    onTriggerEvent (event) {
      // console.info('--trigger--', `on${event.replace(/^\S/, s => s.toUpperCase())}`)
      this.$emit('onRowAction', `on${event.replace(/^\S/, s => s.toUpperCase())}`)
    }
  }
}
</script>

<style scoped>
</style>
