<template>
  <main class="p--main-list">

    <!--    tabs-->
    <section class="list-tab">
      <div
        class="tab-item"
        @click="handleSelectTab(tab)"
        :class="{ 'active': tab.value === filter.clientStatus }"
        v-for="tab in statusTabs"
        :key="tab.value"
      >
        {{ tab.label }}
      </div>
    </section>

    <!--    filter-->
    <section class="list-filter">
      <div class="filters-wrap">
        <ez-select
          v-model="filter.ticketTemplateId"
          :placeholder="$t('common.ticketType')"
          style="width: 210px;margin-right: 10px"
        >
          <ez-option
            v-for="item in listTicketTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </ez-option>
        </ez-select>
        <GroupField
          style="margin-right: 10px"
          v-model="preLabel"
          :options="timeTypes"
        >
          <ez-date-picker
            v-model="times"
            type="datetimerange"
            align="right"
            :default-time="['00:00:00', '23:59:59']"
            unlink-panels
            range-separator="~"
            :start-placeholder="$t('common.startTime')"
            :end-placeholder="$t('common.endTime')"
          />
        </GroupField>
        <ez-input
          prefix-icon="DS__iconfont DS__icon_search"
          clearable
          :placeholder="$t('common.subject')"
          v-model="filter.subject"
          style="width: 210px;
          margin-right: 10px"
        />
      </div>
      <div class="actions-wrap">
        <ez-button
          icon="ez-icon-plus"
          type="primary"
          @click="handleToCreate">
          {{ $t('common.createCase') }}
        </ez-button>
      </div>
    </section>

    <!--    list-->
    <section class="list-table" v-loading="isListLoading">
      <ez-table
        size="mini"
        border
        height="100%"
        :data="tableData"
        style="width: 100%">
        <ez-table-column
          show-overflow-tooltip
          v-for="column in columns"
          :key="column.field"
          :label="$t('common.' + column.label)"
          :min-width="column.minWidth"
          :fixed="column.fixed || false"
          :width="column.width">
          <template slot-scope="{ row }">
            <template v-if="!column.needSlot">
              {{ row[column.field] || '--' }}
            </template>
            <template v-else>

              <!--              caseNo-->
              <template v-if="column.field === 'caseNo'">
                <div class="caseno-wrap">
                  <ez-button class="button-caseno" :class="{'is-dot': !!row.hasNewMessage}" type="text" @click="handleToDetail(row)">
                    {{ row.caseNo }}
                  </ez-button>
                  <TextCopy :text="row.caseNo" />
                </div>
              </template>

              <!--              action-->
              <template v-if="column.field === 'action'">
                <ActionsGroup :ticket-detail="row" :scene="SCENES.LIST_ROW" :call-back="getTableData" />
              </template>

              <!--              status-->
              <template v-if="column.field === 'clientStatus'">
                <StatusTag :status="row.clientStatus" />
              </template>

              <!--              updatedTime-->
              <template v-if="column.field === 'updateTime'">
                {{ row.updateTime | toLocalTime }}
              </template>

              <!--              createTime-->
              <template v-if="column.field === 'createTime'">
                {{ row.createTime | toLocalTime }}
              </template>

            </template>
          </template>
        </ez-table-column>
      </ez-table>
    </section>

    <!--      分页-->
    <section class="pagination">
      <ez-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="filter.curPageNo"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="20"
        :total="pageTotal"
        @size-change="onPageSizeChange"
        @current-change="onPageNoChange"
      />
    </section>
  </main>
</template>

<script>
import { TICKET_STATUS, ACTIONS } from '@/config'
import { removeObjectEmptyValue } from '@/utils/utils'
import mixin from '@/views/mixin'
import StatusTag from '@/views/components/StatusTag'
import ActionsGroup, { SCENES } from '@/views/components/ActionsGroup'
export default {
  mixins: [mixin],
  components: {
    StatusTag,
    ActionsGroup
  },
  data () {
    return {
      TICKET_STATUS,
      ACTIONS,
      SCENES,
      filter: {
        ticketTemplateId: '',
        startCreateTime: null,
        endCreateTime: null,
        startUpdateTime: null,
        endUpdateTime: null,
        subject: null,
        clientStatus: null,
        curPageNo: 1,
        pageSize: 20
      },
      pageTotal: 0,
      preLabel: 1,
      times: null,
      isListLoading: false,
      tableData: []
    }
  },
  computed: {
    timeTypes () {
      return [
        { label: this.$t('common.updateTime'), value: 1 },
        { label: this.$t('common.createTime'), value: 2 }
      ]
    },
    listTicketTypes () {
      return [{ label: this.$t('common.all'), value: null }].concat(this.mixinTicketTypes)
    },
    statusTabs () {
      const result = [{ label: this.$t('common.all'), value: null }]
      for (const key in TICKET_STATUS) {
        const cur = TICKET_STATUS[key]
        result.push({
          label: cur.LABEL,
          value: cur.VALUE
        })
      }
      return result
    },
    timeMap () {
      const startTime = this.times ? this.$utils.toUtcTime(this.times[0]) : null
      const endTime = this.times ? this.$utils.toUtcTime(this.times[1]) : null
      return {
        1: { startUpdateTime: startTime, endUpdateTime: endTime },
        2: { startCreateTime: startTime, endCreateTime: endTime }
      }[this.preLabel]
    },
    columns () {
      let columns = [
        {
          label: 'caseNo',
          field: 'caseNo',
          minWidth: 130,
          needSlot: true
        },
        {
          label: 'ticketType',
          field: 'ticketTypeName',
          minWidth: 200
        },
        {
          label: 'subject',
          field: 'subject',
          minWidth: 200
        },
        {
          label: 'description',
          field: 'description',
          minWidth: 250
        },
        {
          label: 'latestReply',
          field: 'latestReplyContent',
          minWidth: 250
        },
        {
          label: 'updateTime',
          field: 'updateTime',
          minWidth: 180,
          needSlot: true
        },
        {
          label: 'createTime',
          field: 'createTime',
          minWidth: 180,
          needSlot: true
        },
        {
          label: 'status',
          field: 'clientStatus',
          width: 130,
          needSlot: true
        },
        {
          label: 'action',
          field: 'action',
          fixed: 'right',
          minWidth: 160,
          needSlot: true
        }
      ]
      let statusColumn = columns.find(item => item.field === 'clientStatus')
      if (this.filter.clientStatus === null) {
        statusColumn.fixed = 'right'
      } else {
        statusColumn.fixed = false
      }
      return columns
    }
  },
  mounted () {
    this.mixinGetTicketTypes()
    this.getTableData(true)
  },
  watch: {
    'filter.clientStatus' () {
      this.getTableData(true)
    },
    'filter.ticketTemplateId' () {
      this.getTableData(true)
    },
    preLabel () {
      this.getTableData(true)
    },
    times () {
      this.getTableData(true)
    },
    'filter.subject' () {
      this.getTableData(true)
    },
    'filter.curPageNo' () {
      this.getTableData()
    },
    'filter.pageSize' () {
      this.getTableData()
    }
  },
  methods: {
    getTableData (resetPageNo) {
      this.isListLoading = true
      if (resetPageNo) this.filter.curPageNo = 1
      this.$axios.get('/api/shipout-workorder/ticketcase/queryUserTicketCaseList', {
        params: this.getQueryParams()
      }).then(res => {
        this.isListLoading = false
        this.tableData = res.data.records.map(item => ({
          ...item,
          ticketTypeName: this.mixinLang === 'en' ? item.ticketTypeEn : item.ticketTypeCn,
          latestReplyContent: this.$utils.getTextFromHtml(item.latestReplyContent)
        }))
        this.filter.curPageNo = +res.data.current
        this.filter.pageSize = +res.data.size
        this.pageTotal = +res.data.total
      }).catch(error => {
        console.error(error)
        this.isListLoading = false
      })
    },

    getQueryParams () {
      return removeObjectEmptyValue({
        ...this.filter,
        ...this.timeMap
      })
    },

    resetFilter () {
      this.filter.ticketTemplateId = null
      this.filter.startCreateTime = null
      this.filter.endCreateTime = null
      this.filter.startUpdateTime = null
      this.filter.endUpdateTime = null
      this.filter.subject = null
      this.filter.curPageNo = 1
      this.filter.pageSize = 20
      this.times = null
    },

    handleSelectTab (tab) {
      this.filter.clientStatus = tab.value
      this.resetFilter()
    },

    handleToCreate () {
      this.$router.push({ name: 'ticket-create' })
    },

    handleToDetail (row) {
      // this.$router.push({ name: 'ticket-detail', params: { ticketCaseId: row.ticketCaseId } })
      const location = this.$router.resolve({ name: 'ticket-detail', params: { ticketCaseId: row.ticketCaseId } })
      window.open(`${window.location.origin}/${location.href}`)
    },

    onPageSizeChange (size) {
      this.filter.pageSize = size
    },

    onPageNoChange (pageNo) {
      this.filter.curPageNo = pageNo
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.p--main-list{
  height: 100%;
  display: flex;
  flex-direction: column;
  .list-tab{
    padding: 0 $page-gap;
    background: #F9FAFC;
    display: flex;
    align-items: center;
    .tab-item{
      cursor: pointer;
      font-size: 16px;
      padding: 12px 20px;
      margin-right: 40px;
      border-bottom: 4px solid transparent;
      &:hover{
        color: $color-primary;
      }
      &.active{
        color: $color-primary;
        border-color: $color-primary;
      }
    }
  }
  .list-filter{
    padding: $page-gap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filters-wrap{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .list-table{
    flex: 1;
    height: 0;
    padding: 0 $page-gap;
  }
  .pagination{
    display: flex;
    justify-content: flex-start;
    padding: 12px $page-gap 20px $page-gap;
  }
  .caseno-wrap {
    display: flex;
    align-items: center;
  }
  .button-caseno {
    position: relative;
    line-height: 16px;
    /deep/ span{
      // display: flex;
      // align-items: center;
      padding-left: 10px;
    }
    &.is-dot {
      &:before {
        content: ' ';
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        margin-right: 4px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: var(--error-color,#e85252);
      }
    }
  }
}
</style>
